<template>
  <div class='card-box'>
      <el-form ref='form' :model='formData' size='mini' :rules='rules' label-width='100px' label-position='right'>
        <el-form-item label='真实姓名：' prop='name'>
          <el-input v-model='formData.name' placeholder='真实姓名' />
        </el-form-item>
        <el-form-item label='用户昵称：' prop='nickname'>
          <el-input v-model='formData.nickname' placeholder='用户昵称' />
        </el-form-item>
        <el-form-item label='手机号码：' prop='mobile'>
          <el-input v-model='formData.mobile' maxlength='11' placeholder='手机号码'/>
        </el-form-item>
        <el-form-item label='邮箱：' prop='email'>
          <el-input v-model='formData.email' maxlength='50' placeholder='邮箱' />
        </el-form-item>
        <el-form-item style='text-align: left'>
          <el-button type='primary' @click='submit'>保存</el-button>
        </el-form-item>
      </el-form>
  </div>
</template>

<script>
export default {
  name: 'userInfo',
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    formData: {
      get() {
        return this.user
      },
      set(val) {
        this.$emit('update:user', val)
      }
    }
  },
  data() {
    return {
      // 表单校验
      rules: {
        nickname: [
          { required: true, message: '用户昵称不能为空', trigger: 'blur' }
        ],
        email: [
          { required: false, message: '邮箱地址不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '\'请输入正确的邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        mobile: [
          { required: true, message: '手机号码不能为空', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    async submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          //修改个人信息，不需要用户ID
          let postData = {
            // id: this.formData.id,
            nickname: this.formData.nickname,
            name: this.formData.name,
            mobile: this.formData.mobile,
            email: this.formData.email,
          }
          let info = this.$api.updateUserProfile(postData)
          if (info) {
            this.$notify.success(`修改成功`)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

.card-box {
  padding: 10px 20px;
  line-height: 380px;
  height: 160px;
  /*width: 500px;*/
  margin-bottom: 20px;
  border-radius: 10px;
}
</style>
