<template>
  <div class="card-box">
    <!--    <el-card>-->
    <el-form ref='form' :model='user' :rules='rules' label-width='100px' size='medium' label-position='right'>
      <!--        <el-form-item label="旧密码：" prop="oldPassword" v-if="hasPwd">-->
      <!--          <el-input v-model="user.oldPassword" placeholder="请输入旧密码" type="password"/>-->
      <!--        </el-form-item>-->
      <el-form-item label='新密码：' prop='newPassword'>
        <el-input v-model='user.newPassword'
                  placeholder='请输入新密码：必须包含大小写字母和数字的组合，不能使用特殊字符，长度在8-20之间'
                  type='password' clearable>
          <!--            <span slot='append'>{{user.newPassword?user.newPassword.length:0}} 位</span>-->
        </el-input>
      </el-form-item>
      <el-form-item label='确认密码：' prop='confirmPassword'>
          <el-input v-model='user.confirmPassword' placeholder='请确认密码' type='password' clearable>
<!--            <span slot='append'>{{user.confirmPassword?user.confirmPassword.length:0}} 位</span>-->
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type='primary' @click.stop.native='submit'>保存</el-button>
        </el-form-item>
      </el-form>
    <!--    </el-card>-->
  </div>
</template>

<script>
export default {
  name: 'ResetPwd',
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      // 表单校验
      rules: {
        // oldPassword: [
        //   {required: true, message: "旧密码不能为空", trigger: "blur"}
        // ],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          // { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          {
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z]{8,20}$/,
            message: '必须包含大小写字母和数字的组合，不能使用特殊字符，长度在8-20之间',
            trigger: ['change','blur']
          }
        ],
        confirmPassword: [
          { required: true, message: '确认密码不能为空', trigger: 'blur' },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ]
      },
      hasPwd: true//是否有密码
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.$api.updateUserPwd(this.user.oldPassword, this.user.newPassword).then(() => {
              // console.log(response)
              // this.msgSuccess("修改成功");
              this.$alert('【确认】后返回登录页面', '修改成功', {
                confirmButtonText: '确定',
                callback: action => {
                  if (action) {
                    this.handleLogout()
                  }
                  // this.$message({
                  //   type: 'info',
                  //   message: `action: ${action}`
                  // });
                }
              })
            }
          )
        }
      })
    },
    // 处理退出
    handleLogout() {
      this.$store.dispatch('LogOut').then(() => {
        this.$router.push({ path: `/login?redirect=${this.$route.fullPath}` })
        // location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    },
    async checkHasPwd() {
      //判断用户是否已经有密码了
      let { info } = await this.$api.checkUserPwd()
      this.hasPwd = !!info
    }
  },
  created() {
    this.checkHasPwd()
  }
}
</script>

<style scoped>
.card-box {
  padding: 10px 20px;
  line-height: 380px;
  height: 150px;
  /*width: 500px;*/
  /*border: #2c2727 1px dashed;*/
  margin-bottom: 20px;
  border-radius: 10px;
}
</style>
