<template>
  <div>
    <PageHeaderLayout>
      <div class="main-page-content">
        <h2>修改个人信息</h2>

        <el-row>
          <el-col :span="12" style="padding: 20px">
            <el-tabs v-model='activeTab' class='text-center'>
              <el-tab-pane label='基本资料' name='userinfo'>
                <UserInfo :user.sync='user'/>
              </el-tab-pane>
              <el-tab-pane label='修改密码' name='resetPwd'>
                <ResetPwd/>
              </el-tab-pane>
              <el-tab-pane label='权限申请' name='permissions'>
                <user-permissions></user-permissions>
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="16"></el-col>
        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import ResetPwd from '@/pages/user/profile/ResetPwd'
import UserInfo from '@/pages/user/profile/UserInfo'
import userPermissions from '@/pages/user/components/userPermissions.vue'

export default {
  name: 'UserProfile',
  data() {
    return {
      activeTab: 'userinfo',
      user: {}

    }
  },
  components: { ResetPwd, UserInfo, userPermissions },
  methods: {
    async getUser() {
      let { user } = await this.$api.getUserProfile()
      this.user = user
      if (user.avatar) this.userAvatar = user.avatar
    }
  },
  created() {
    this.getUser()
  }
}
</script>

<style scoped>

</style>
